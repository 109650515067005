import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId, VerticalContent } from "../components/Navigation";
import Accordion from "../components/Accordion";
import CommonButton from "../components/Button";
import Map from "../svgComponents/Map";
import { COLORS } from "../utils/consts";
import { getIsVertical } from "../utils/getIsVertical";

import "../styles/mobila-paliativa-aprupe-majas.css";

export default function MobilePalliativeCare() {
	const isVertical = getIsVertical();
	const [isExpanded, setIsExpanded] = React.useState(isVertical);

	return (
		<Layout pageId={PageId.MobilePalliativeCare}>
			<Seo
				title="Mobilā paliatīvā aprūpe mājās"
				description="Mēs uzskatām, ka mūža nogali ikvienam cilvēkam ir tiesības pavadīt cieņpilni. Arī tad, ja nepieciešama nopietna aprūpe. Un tādu mēs piedāvājam, turklāt mājās."
			/>
			<div className="contentBlock topTextSection">
				<h1>
					Mēs uzskatām, ka mūža nogali ikvienam {isVertical ? "" : <br />}cilvēkam ir tiesības pavadīt cieņpilni.<br />{isVertical ? <br /> : ""}
					Arī tad, ja nepieciešama nopietna aprūpe.<br />
					Un tādu mēs piedāvājam, turklāt mājās.
				</h1>
			</div>
			<div className="contentBlock noPadding" style={{ display: "flex", flexDirection: isVertical ? "column" : "row" }}>
				<div style={{ width: isVertical ? "" : "130%", padding: isVertical ? "3rem" : "5rem 9rem 5rem 5rem", order: isVertical ? 2 : 1 }}>
					<p style={{ fontSize: isVertical ? "3rem" : "1.4rem", margin: isVertical? "2rem 0 6rem" : "3rem 0" }}>
						Kas ir paliatīvā aprūpe?
					</p>
					<p style={{ fontSize: isVertical ? "" : "1.4rem", margin: isVertical ? "3rem 0" : "2rem 0", fontFamily: "WT Monarch Nova, Untitled Sans, sans-serif" }}>
						Paliatīvā aprūpe ir specializēta medicīniskā aprūpe cilvēkiem, kuriem diagnosticēta slimība terminālā stadijā. Šāda veida aprūpe ir vērsta uz slimības simptomu un stresa mazināšanu. Mērķis ir uzlabot dzīves kvalitāti gan pacientam, gan ģimenei.
					</p>
					<p style={{ fontSize: isVertical ? "" : "1.4rem", margin: isVertical ? "3rem 0" : "2rem 0", fontFamily: "WT Monarch Nova, Untitled Sans, sans-serif" }}>
						Paliatīvo aprūpi nodrošina īpaši apmācīta ārstu, medmāsu, ārstu palīgu, koordinatoru un citu speciālistu komanda. Paliatīvā aprūpe balstās uz pacienta vajadzībām, nevis uz pacienta prognozi.
					</p>
				</div>
				<div style={{ borderBottom: isVertical ? `1px solid ${COLORS.white}` : "", height: isVertical ? "50rem" : "", order: isVertical ? 1 : 2 }}>
					<StaticImage
						src="../images/mobila-paliativa-aprupe-majas/1.jpeg"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Mobila paliatīvā aprūpe mājās"
						placeholder="blurred"
						loading="eager"
						style={{
							width: "100%",
							height: "100%",
						}}
					/>
				</div>
			</div>
			<div className="contentBlock" style={isVertical ? { padding: "3rem" } : {}}>
				<Accordion
					items={[
						{
							Title: <p style={{ textAlign: "left" }}>Medicīniskie pakalpojumi</p>,
							Content: (
								<div className="accordionMenuContent">
									<p>Ārsta palīga un/vai medicīnas māsas sniegtie veselības aprūpes pakalpojumi — medikamentu ievadīšana, pēcoperācijas brūču, izgulējumu un trofisko čūlu aprūpe, stomu aprūpe, porta katetra aprūpe, pacienta vai pacienta aprūpes procesā iesaistītās personas izglītošana un praktiska apmācība veselības aprūpes jomā, laboratoriski izmeklējamo bioloģiskā materiāla savākšana un nogādāšana laboratorijā. vitālo rādītāju kontrole, klizmas veikšana, elektrokardiogrāfijas veikšana, vakcinācijas veikšana.</p>
									<p>Fizioterapeita, ergoterapeita un uztura speciālista pakalpojumi atbilstoši kompetencei.</p>
									<p>Plānveida vizītes stacionāros, plānveida speciālistu konsultācijas, diagnostiskie izmeklējumi.</p>
									<p>Pacientu transportēšana uz stacionāru, uz speciālista konsultāciju vai diagnostisko procedūru un atpakaļ no tās ar specializēto transportu, t.sk. nodrošinot guļošo pacientu transportēšanu.</p>
									<p>Specializēto kabinetu pakalpojumu nepārtrauktības koordinēšana sadarbības ietvaros (skābekļa terapijas pakalpojumi mājās pacientiem, atbilstoši noteiktai kārtībai, psihoemocionālā atbalsta pakalpojumi pacientiem ar onkoloģisku diagnozi, problēmsituācijas, kas saistītas ar enterālās vai parenterālās barošanas nodrošināšanu, problēmsituāciju, kas saistītas ar pacientu traheostomu aprūpi).</p>
								</div>
							),
						},
						{
							Title: <p style={{ textAlign: "left" }}>Sociālās aprūpes un psihosociālās rehabilitācijas pakalpojumi</p>,
							Content: (
								<div className="accordionMenuContent">
									<p>Klīniskā un veselības psihologa vai konsultatīvā psihologa konsultācijas.</p>
									<p>Sociālā darbinieka pakalpojumi.</p>
									<p>Kapelāna pakalpojumi.</p>
									<p>Sociālā aprūpētāja pakalpojumi, t.sk. pacienta pamatvajadzību (fiziska aprūpe, higiēnas pocedūras, ikdienas mājas darbi) nodrošināšana un dzīves kvalitātes uzturēšana atbilstoši pacienta individuālajā aprūpes plānā noteiktajam.</p>
									<p>Pacienta tuvinieku apmācība par aprūpes paņēmieniem, aprūpes palīglīdzekļu lietošanu.</p>
									<p>Tehnisko palīglīdzekļu nodrošināšana, to piegāde, uzstādīšana un apmācība.</p>
								</div>
							),
						},
					]}
				/>
			</div>
			<div className="contentBlock noPadding">
				<div className="imageWithExpandableSection">
					<StaticImage
						src="../images/mobila-paliativa-aprupe-majas/2.jpeg"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Kā pieteikties?"
						placeholder="blurred"
						imgStyle={{ margin: isVertical ? "" : "-1rem 0", height: isVertical ? "" : "calc(100% + 2rem)" }}
					/>
					<div className={`expandableSection ${isExpanded ? "expanded" : ""}`} onClick={() => { if (!isVertical) { setIsExpanded(true); }} }>
						<div className={`expandableSectionTitle ${isExpanded ? "" : "visible"}`}>
							<VerticalContent content={"Kā pieteikties?"} />
						</div>
						<div className={`expandableSectionContent ${isExpanded ? "visible" : ""}`}>
							<p style={{ fontFamily: "Untitled Sans, sans-serif", fontSize: isVertical ? "2.7rem" : "", marginBottom: isVertical ? "5rem" : "" }}>Kā pieteikties?</p>
							<p>Lai saņemtu mobilās paliatīvās aprūpes pakalpojumu mājās, nepieciešams slimnīcas konsīlija lēmums par paliatīvās aprūpes statusa piešķiršanu konkrētam pacientam.</p>
							<p>Rūre pakalpojumi pieejami pieejami Kurzemes un Latgales lielākajās pilsētās, Rīgas kreisajā krastā un Pierīgā.</p>
							<p>Paliatīvo aprūpi Rūre nodrošina, saskaņā ar individualizētu medicīniskās, fizikālās, garīgās un sociālās aprūpes plānu. No tā atkarīgs apmeklējumu skaits nedēļā, zāļu lietošana un citas manipulācijas. Aprūpes sniegšanas ietvaros Rūre padziļināti sadarbojas un komunicē ar pacienta piederīgajiem, nodrošinot viņu apmācību un izglītošanu pareizā un kvalitatīvā pacienta apkopē.</p>
							<p>Pēc konsīlija slēdziena saņemšanas, pacients un/vai pacienta piederīgie var sazināties ar Rūre koordinatoru pa zemāk norādīo tālruni.</p>
							<a target="_blank" href="https://www.lm.gov.lv/lv/paliativas-aprupes-mobilas-komandas-pakalpojums-pacienta-dzivesvieta"><CommonButton style={{ borderColor: COLORS.lightGreen }}>UZZINI VAIRĀK</CommonButton></a>
						</div>
					</div>
				</div>
			</div>
			<div className="contentBlock">
				<p className="contactUs">Sazinies ar mums!</p>
				<Map />
			</div>
		</Layout>
	);
}
